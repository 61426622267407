import React from 'react'
import ImagePreview from '../ImagePreview/ImagePreview'

const ImageGallery = ({ fotos }) => {
  return (
    <div className="columns is-multiline">
      {fotos.map(file => (
        <div key={file} className="column is-one-third">
          <ImagePreview file={file} thumb />
        </div>
      ))}
    </div>
  )
}

export default ImageGallery
