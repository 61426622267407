import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout/Layout'
import ImageGallery from '../wal-common/src/components/Image/ImageGallery/ImageGallery'

const Fotoalbum = ({
  data: {
    markdownRemark: {
      frontmatter: { fotos },
    },
  },
}) => {
  return (
    <Layout cols={true} title="Fotoalbum">
      <ImageGallery fotos={fotos} />
    </Layout>
  )
}

export default Fotoalbum

export const pageQuery = graphql`
  query FotoAlbumID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        fotos
      }
    }
  }
`
